<template>
  <section class="tables">
    
    <div class="page-header">
      <h3 class="page-title">Theme Management </h3>
      <nav aria-label="breadcrumb">
        <div class="float-right">
          <router-link class="btn btn btn-add btn-primary" :to="`/website-management/add-theme`"
            >Add New
          </router-link>
        </div>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="table-responsive mb-0">
            <table class="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th>Name</th> 
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr > 
                  <td>Shoplattice - VueJS eCommerce Template </td> 
                  <td>
                    <router-link
                      class="mr-2 table-icon"
                      v-b-tooltip.hover
                      title="Edit"
                      :to="`/website-management/edit-theme`"
                    >
                      <i class="mdi mdi-pencil"></i>
                    </router-link>
                    <a   class="mr-2 table-icon" v-b-tooltip.hover title="Delete">
                      <i class="mdi mdi-delete"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "invoice-list",
  components: {
    
  },
  data() {
    return {
      
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>
